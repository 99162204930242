<script setup lang="ts">
import type { User } from "@gasparigit/omnia-sdk";
import { z } from "zod";
import { processError, requiredError, successToast } from "~/lib/utils/errors";
import { userFieldConfigs, userFormSchema } from "~/lib/utils/forms";
import CheckboxPreference from "../fields/CheckboxPreference.vue";
import type { ConfigItem } from "../ui/auto-form";

const omniaSdk = useOmniaSdk();

const open = defineModel<boolean>("open");
const emit = defineEmits(["success"]);

const props = defineProps<{
  user: User;
}>();

const formSchema = z
  .object(userFormSchema)
  .omit({ email: true })
  .extend({
    // has_newsletter: z.boolean().optional(),
    has_marketing: z.boolean().optional(),
    user_policy_accepted: z.boolean(requiredError).refine((value) => value, {
      message: "Devi accettare l'informativa sulla privacy.",
      path: ["user_policy_accepted"],
    }),
  });

const form = useForm({ validationSchema: toTypedSchema(formSchema) });

form.setValues({ ...props.user, user_policy_accepted: false });

const fieldConfig: Record<string, ConfigItem> = {
  ...userFieldConfigs,
  // has_newsletter: {
  //   label: "Ricevi email del servizio Memoweb",
  //   component: CheckboxPreference,
  // },
  has_marketing: {
    label: "marketing",
    component: CheckboxPreference,
  },
  user_policy_accepted: {
    label: "privacy_policy",
    component: CheckboxPreference,
  },
};

async function onSubmit(values: Record<string, any>) {
  try {
    await omniaSdk.auth.user.update({ ...values, is_first_login: false, user_policy_last_acceptance: true, privacy_policy_last_acceptance: true, cookies_policy_last_acceptance: true });

    successToast();
    emit("success");
  } catch (error) {
    processError(error);
  }
}
</script>

<template>
  <Dialog v-model:open="open">
    <DialogScrollContent class="max-w-3xl" @pointer-down-outside.prevent unclosable>
      <DialogHeader>
        <DialogTitle>Benvenuto su Progetto Omnia</DialogTitle>
        <DialogDescription>
          <p>Benvenuto! Ti invitiamo a rivedere e compilare i dati sottostanti per completare il tuo profilo.</p>
        </DialogDescription>
      </DialogHeader>

      <AutoForm @submit="onSubmit" :form="form" :schema="formSchema" :field-config="fieldConfig" class="content-card-grid grid gap-4">
        <!-- <template #has_newsletter="slotProps">
          <div :class="{ hidden: user.has_newsletter }">
            <AutoFormField v-bind="slotProps" />
          </div>
        </template> -->
        <template #has_marketing="slotProps">
          <div :class="{ hidden: user.has_marketing }">
            <AutoFormField v-bind="slotProps" />
          </div>
        </template>

        <DialogFooter class="mt-4">
          <Button type="submit" :disabled="!form.meta.value.valid">Salva</Button>
        </DialogFooter>
      </AutoForm>
    </DialogScrollContent>
  </Dialog>
</template>
